import React, { Component } from "react";
import {
  Button,
  CheckPicker,
  InputGroup,
  Input,
  Modal,
  Divider,
  Toggle,
  Drawer,
  DatePicker,
  Nav,
  Badge,
  Calendar,
  Tag,
  List,
} from "rsuite";
import { loadRoutes, loadVisitData } from "../Loaders/GeneralLoder";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import FunnelIcon from "@rsuite/icons/Funnel";
import ListIcon from "@rsuite/icons/List";
import ExploreIcon from "@rsuite/icons/Explore";
import ReloadIcon from "@rsuite/icons/Reload";
import { MdOutlineGraphicEq } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoStorefront } from "react-icons/io5";
import { MdOutlineEventAvailable } from "react-icons/md";

import {
  InfoWindow,
  Marker,
  Circle,
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  Polygon,
  Polyline,
  DirectionsRenderer,
  MarkerClusterer,
  PolygonF,
  PolylineF,
  MarkerClustererF,
  MarkerF,
} from "@react-google-maps/api";
import Cookies from "js-cookie";
import constants, { alertInfoSilent, getMarkerImage } from "../constants";
import CustomerView from "./sub-pages/mobile/customer-view";
import HomeLeadView from "./sub-pages/mobile/home-lead-view";
import moment from "moment";
import CustomLoader from "../Widgets/CustomLoader";
import LeadListItem from "../components/LeadListItem";
import { deepCopy, getFavCustomers } from "../Helpers/Utilities";
import SalesQtyList from "../Modules/SalesQtyList";
import CustomerListItem from "../components/CustomerListItem";
import { getRouteZones } from "../Helpers/CoordinateHelper";

class Visits extends Component {
  state = {
    routes: [],
    selectedRoutes: JSON.parse(Cookies.get("selectedRoutes") ?? "[]"),
    loading: false,
    followMe: false,
    map: null,
    filterGoogleMapPlaces: false,
    filterCVisits: false,
    filterEventTypes: JSON.parse(Cookies.get("filterEventTypes") ?? "[]"),
    selectedCustomer: null,
    selectedLead: null,
    selectedLeadMini: null,
    navKey: "all",
    mode: "dash",
    eventDates: [],
    filteredData: null,
  };

  componentDidMount = () => {
    loadRoutes((routes) => this.setState({ routes }));
    if (this.state.selectedRoutes?.length > 0) {
      this.loadData();
    }
  };

  componentDidUpdate = () => {
    //stick map position to current location..
    if (this.state.map && this.state.followMe) {
      this.state.map.setCenter(this.props.currentLocation);
    }
  };

  loadData = () => {
    this.setState({ loading: true });
    loadVisitData(this.state.selectedRoutes, (data) => {
      if (this.props.sessionCheck.role != "Admin") {
        //BAD APPROACH CHANGE LATER.... HIDING VISITS FROM OTHER USERS..
        data.visits = [];
      }
      var eventDates = data?.events?.map(
        (event) => new Date(event?.last_followup?.event_date)
      );

      this.setState({ data, eventDates, loading: false }, this.filteredData);
    });
  };

  filteredData = () => {
    var { data, filterEventDate, filterEventTypes } = this.state;

    if (!data) {
      return null;
    }

    var newData = deepCopy(data);

    if (filterEventDate) {
      console.log("Event date filter applied....");
      newData.events = newData.events.filter((lead) =>
        moment(lead.last_followup.event_date).isSame(
          moment(filterEventDate),
          "day"
        )
      );
    }

    // //display as per EventTypes
    newData.events = newData.events.filter((lead) =>
      filterEventTypes.includes(lead.last_followup.event_type)
    );

    this.setState({ filteredData: newData });

    return newData;
  };

  render() {
    var {
      routes,
      selectedRoutes,
      loading,
      followMe,
      selectedCustomer,
      selectedLead,
      filterEventDate,
      selectedLeadMini,
      navKey,
      mode,
      eventDates,
      filteredData,
    } = this.state;

    const data = filteredData;
    return (
      <div>
        <CustomLoader full text="Loading events data" loading={loading} />
        <InputGroup>
          {/* <Button
            disabled={loading}
            onClick={() => this.setState({ mode: "dash" })}
            appearance={mode == "dash" ? "primary" : "default"}
            size="md"
          >
            <MdOutlineGraphicEq />
          </Button> */}
          {/* {navKey == "deliveries" && <SalesQtyList sales={data?.delivery} />} */}
          {/* <Button
            disabled={loading}
            onClick={() => this.setState({ mode: "map" })}
            appearance={mode == "map" ? "primary" : "default"}
            size="md"
          >
            <FaMapMarkedAlt />
          </Button> */}

          <CheckPicker
            size="sm"
            label="Routes"
            data={routes.map((route) => ({
              label: route.route_name,
              value: route.id,
            }))}
            loading={loading}
            value={selectedRoutes}
            onChange={(selectedRoutes) => {
              this.setState({ selectedRoutes });
              Cookies.set("selectedRoutes", JSON.stringify(selectedRoutes));
            }}
          />
          <Button disabled={loading} onClick={this.loadData} size="md">
            <ReloadIcon />
          </Button>
          <Button
            disabled={loading}
            size="xs"
            onClick={() => this.setState({ filterModal: true })}
          >
            <FunnelIcon />
          </Button>
          <Button
            disabled={loading}
            onClick={() => this.setState({ selectedLeadMini: null })}
            appearance={selectedLeadMini != null ? "primary" : "default"}
            size="xs"
          >
            <ListIcon />
          </Button>
          <Button
            disabled={loading}
            onClick={() => this.setState({ followMe: !followMe })}
            appearance={followMe ? "primary" : "default"}
            color="green"
            size="xs"
          >
            <ExploreIcon />
          </Button>
        </InputGroup>

        {/* Mode Nav */}
        {this.state.filterEventDate && (
          <Nav
            appearance="subtle"
            justified
            defaultActiveKey="dash"
            activeKey={mode}
            onSelect={(mode) => this.setState({ mode })}
          >
            <Nav.Item eventKey="dash">Calendar</Nav.Item>
            <Nav.Item eventKey="list">List</Nav.Item>
            <Nav.Item eventKey="map">Map</Nav.Item>
          </Nav>
        )}

        {/* Display type nav */}
        <Nav
          appearance="subtle"
          justified
          activeKey={navKey}
          onSelect={(navKey) => this.setState({ navKey })}
        >
          {/* <Nav.Item eventKey="all">
            <Badge
              maxCount={1000}
              content={
                data?.delivery?.length +
                  data?.events?.length +
                  data?.visits?.length ?? 0
              }
            >
              All
            </Badge>
          </Nav.Item> */}
          <Nav.Item eventKey="all">
            <Badge maxCount={1000} content={data?.events?.length ?? 0}>
              All
            </Badge>
          </Nav.Item>

          <Nav.Item eventKey="morning">
            <Badge
              maxCount={1000}
              content={
                data?.events?.filter(
                  (event) => event.last_followup.an_visit === 0
                ).length ?? 0
              }
            >
              Morning
            </Badge>
          </Nav.Item>

          <Nav.Item eventKey="afternoon">
            <Badge
              maxCount={1000}
              content={
                data?.events?.filter(
                  (event) => event.last_followup.an_visit === 1
                ).length ?? 0
              }
            >
              After-noon
            </Badge>
          </Nav.Item>
        </Nav>

        {/* Dashboard mode */}
        {mode === "dash" && (
          <div>
            <Calendar
              defaultValue={new Date()} // Default date
              onSelect={(date) =>
                this.setState(
                  {
                    filterEventDate: date,
                    mode: "list",
                  },
                  this.filteredData
                )
              }
              renderCell={(date, dateState) => {
                const { currentMonthData } = this.state?.data || {};

                // Get number of customers created on this date
                const customersOnDate = currentMonthData?.[date.getDate()] || 0;

                // Get number of customers created on the previous day
                const yesterday = new Date(date);
                yesterday.setDate(date.getDate() - 1);
                const customersYesterday =
                  currentMonthData?.[yesterday.getDate()] || 0;

                // Determine color based on comparison
                let customerColor = "#2282C6";
                if (customersOnDate > customersYesterday) {
                  customerColor = "#81B641";
                } else if (customersOnDate < customersYesterday) {
                  customerColor = "#E63131";
                }

                // Count number of events on this date
                const eventsOnDate =
                  eventDates?.filter(
                    (eventDate) =>
                      eventDate.getDate() === date.getDate() &&
                      eventDate.getMonth() === date.getMonth() &&
                      eventDate.getFullYear() === date.getFullYear()
                  ).length || 0;

                return (
                  <div>
                    <div
                      style={{
                        fontWeight: "bold",
                        border: "1px solid #C9C9C9",
                      }}
                    >
                      <span style={{ color: customerColor }}>
                        <IoStorefront /> &nbsp;
                        {customersOnDate}{" "}
                      </span>
                      <br />
                      <MdOutlineEventAvailable />
                      {eventsOnDate}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}

        {/* Map Mode */}
        {mode == "map" && (
          <>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: selectedLeadMini != null ? "40vh" : "76vh",
              }}
              zoom={10}
              onLoad={(map) => {
                this.setState({ map });
                map.setCenter(this.props.currentLocation);
              }}
              onUnmount={() => this.setState({ map: null })}
              mapTypeId="roadmap"
              options={{
                styles: !this.state.filterGoogleMapPlaces
                  ? [
                      {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }], // Hides all points of interest (places marker)
                      },
                    ]
                  : [], // Empty styles array means no styles are applied
              }}
            >
              {selectedRoutes.map((selected) =>
                routes.map(
                  (route) =>
                    route.id == selected && (
                      <>
                        <PolygonF
                          options={{
                            paths: JSON.parse(route?.cord1).map((coord) => ({
                              lat: parseFloat(coord.lat),
                              lng: parseFloat(coord.lng),
                            })),
                            strokeColor: "#1abc9c",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillOpacity: 0,
                          }}
                        />
                        <PolylineF
                          path={getRouteZones(route)?.verticalLine}
                          options={{
                            strokeColor: "#697987",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                          }}
                        />
                        <PolylineF
                          path={getRouteZones(route)?.horizontalLine}
                          options={{
                            strokeColor: "#697987",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                          }}
                        />
                      </>
                    )
                )
              )}

              {data?.events
                ?.filter((lead) => {
                  if (navKey === "morning") {
                    return lead.last_followup.an_visit === 0; // 0 for false
                  } else if (navKey === "afternoon") {
                    return lead.last_followup.an_visit === 1; // 1 for true
                  }
                  return true; // No filter for "all"
                })
                .map((lead, index) => (
                  <MarkerF
                    key={`E${index}`}
                    position={JSON.parse(lead.geolocation)}
                    icon={{
                      url:
                        lead.last_followup.event_type ===
                        constants.event_types[1]
                          ? getMarkerImage(
                              "GREEN",
                              lead.last_followup.an_visit === 1
                            )
                          : lead.last_followup.event_type ===
                            constants.event_types[2]
                          ? getMarkerImage(
                              "YELLOW",
                              lead.last_followup.an_visit === 1
                            )
                          : getMarkerImage(
                              "BLUE",
                              lead.last_followup.an_visit === 1
                            ),
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: lead.shop_name,
                      color: "black",
                      fontSize: "8",
                    }}
                    onClick={() => {
                      this.setState({ selectedLeadMini: lead });
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                        JSON.parse(lead.geolocation).lat
                      },${
                        JSON.parse(lead.geolocation).lng
                      }&travelmode=motorcycle`)
                    }
                  />
                ))}

              {/* show c visits */}
              {this.state.filterCVisits &&
                data?.cVisits?.map((customer, index) => (
                  <MarkerF
                    key={`V${index}`}
                    position={JSON.parse(customer.geo_location)}
                    icon={{
                      url: constants.marker_pink,
                      scaledSize: new window.google.maps.Size(32, 32), // specify the size of the icon
                    }}
                    label={{
                      text: "C",
                      color: "white",
                      fontSize: "10",
                    }}
                    onClick={() => {
                      alertInfoSilent(
                        customer.shop_name + " (View)",
                        3000,
                        () => this.setState({ selectedCustomer: customer })
                      );
                      return false;
                    }}
                    onDblClick={() =>
                      (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${
                        JSON.parse(customer.geo_location).lat
                      },${
                        JSON.parse(customer.geo_location).lng
                      }&travelmode=motorcycle`)
                    }
                  />
                ))}

              <MarkerF
                position={this.props.currentLocation}
                label={{
                  text: "You",
                  fontSize: "10",
                }}
              />
            </GoogleMap>
          </>
        )}

        {/* List Mode */}
        {mode === "list" && (
          <List>
            {data?.events
              ?.filter((lead) => {
                if (navKey === "morning") {
                  return lead.last_followup.an_visit === 0; // 0 for false
                } else if (navKey === "afternoon") {
                  return lead.last_followup.an_visit === 1; // 1 for true
                }
                return true; // No filter for "all"
              })
              .map((lead, index) => (
                <List.Item key={`leadlist-${index}`}>
                  <LeadListItem
                    lead={lead}
                    onClick={() => {
                      this.setState({ selectedLead: lead });
                    }}
                  />
                </List.Item>
              ))}
          </List>
        )}

        {/* selected lead mini view */}
        {selectedLeadMini != null && (
          <LeadListItem
            lead={selectedLeadMini}
            onClick={() => {
              this.setState({ selectedLead: selectedLeadMini });
            }}
          />
        )}

        {/* Filter Modal */}
        <Modal
          open={this.state.filterModal}
          onClose={() => this.setState({ filterModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Divider>Filter List</Divider>
            <i>Event Date Filter</i>
            <DatePicker
              oneTap
              block
              editable={false}
              value={this.state.filterEventDate}
              onChange={(filterEventDate) => {
                this.setState({ filterEventDate }, this.filteredData);
              }}
            />

            <i>Event Types</i>
            <CheckPicker
              size="sm"
              label="Event Types"
              data={constants.event_types.map((event_type) => ({
                label: event_type,
                value: event_type,
              }))}
              value={this.state.filterEventTypes}
              onChange={(filterEventTypes) => {
                this.setState({ filterEventTypes });
                console.log(filterEventTypes);
                Cookies.set(
                  "filterEventTypes",
                  JSON.stringify(filterEventTypes)
                );
              }}
            />

            <Divider>Map Options</Divider>
            <Toggle
              size="xs"
              checked={this.state.filterGoogleMapPlaces}
              checkedChildren="Places"
              unCheckedChildren="Places"
              onChange={(filterGoogleMapPlaces) => {
                this.setState({ filterGoogleMapPlaces });
              }}
            />

            <Toggle
              size="xs"
              checked={this.state.filterCVisits}
              checkedChildren={`C-Visits(${data?.cVisits?.length})`}
              unCheckedChildren={`C-Visits(${data?.cVisits?.length})`}
              onChange={(filterCVisits) => {
                this.setState({ filterCVisits });
              }}
            />
          </Modal.Body>
        </Modal>

        {/* Lead & Custoemr View Drawer */}
        {selectedCustomer != null || selectedLead != null ? (
          <Drawer
            placement="bottom"
            size="full"
            open={selectedCustomer != null || selectedLead != null}
            onClose={() =>
              this.setState({ selectedCustomer: null, selectedLead: null })
            }
          >
            <Drawer.Header>
              <Drawer.Title>
                View {selectedCustomer ? "Customer" : "Lead"}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {selectedCustomer ? (
                <CustomerView
                  data={selectedCustomer}
                  back={() => this.setState({ selectedCustomer: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                />
              ) : selectedLead ? (
                <HomeLeadView
                  lead={selectedLead}
                  back={() => this.setState({ selectedLead: null })}
                  currentLocation={this.props.currentLocation}
                  sessionCheck={this.props.sessionCheck}
                  setView={(selectedLead) => this.setState({ selectedLead })}
                  loadLeads={this.loadData}
                />
              ) : (
                ""
              )}
            </Drawer.Body>
          </Drawer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Visits;
